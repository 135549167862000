import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { Input } from "../../../shared/system";
import { usePermissions } from "../../workspace/hooks/usePermissions";
import { updateTitle } from "../boardFunctions";

interface Props {
	projectId: string;
	rootId: string;
	boardTitle?: string;
}

export const BoardHeaderTitle = ({ projectId, rootId, boardTitle }: Props) => {
	const { canEdit } = usePermissions("project");

	const [title, setTitle] = useState<string | undefined>(boardTitle);

	useEffect(() => {
		setTitle(boardTitle);
	}, [boardTitle]);

	const handleTitleChange = (e: React.SyntheticEvent<HTMLInputElement>) => setTitle(e.currentTarget.value);

	const handleTitleBlur = (e: React.SyntheticEvent<HTMLInputElement>) =>
		updateTitle(projectId, rootId, e.currentTarget.value);

	return <Root value={title} onChange={handleTitleChange} onBlur={handleTitleBlur} disabled={!canEdit} plain hover />;
};

const Root = styled(Input)`
	margin: 0;
	margin-bottom: 8px;
	background: transparent;
	padding: 0;
	font-weight: 600;
	font-size: 32px;
	line-height: 40px;
	text-align: center;
	color: var(--color-text-heading);
	text-overflow: ellipsis;
	field-sizing: content;

	:disabled {
		opacity: 1;
		background: transparent;
		-webkit-text-fill-color: var(--color-text-heading);
	}
`;
